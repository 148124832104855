'use client';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography/Typography';
import { FlexRowCenter } from 'components/flex-box';

interface Props {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function Error({ error, reset }: Props) {
  return (
    <FlexRowCenter height="60vh">
      <Card sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h1" mb={2}>
          Something went wrong!
        </Typography>

        <Button color="error" variant="contained" onClick={() => reset()}>
          Try again
        </Button>
      </Card>
    </FlexRowCenter>
  );
}
